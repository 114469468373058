import { get, post } from '../../../../utils/api'
import config from '../../../../configuration/index'

export const dataActions = {
  FETCH_SIMDATA_REQUEST: 'FETCH_SIMDATA_REQUEST',
  FETCH_SIMDATA_SUCCESS: 'FETCH_SIMDATA_SUCCESS',
  FETCH_SIMDATA_REJECT: 'FETCH_SIMDATA_REJECT',
  FETCH_METADATA_REQUEST: 'FETCH_METADATA_REQUEST',
  FETCH_METADATA_SUCCESS: 'FETCH_METADATA_SUCCESS',
  FETCH_METADATA_REJECT: 'FETCH_METADATA_REJECT',
  CLEAR_METADATA: 'CLEAR_METADATA',
  FETCH_ALL_METADATA_REQUEST: 'FETCH_ALL_METADATA_REQUEST',
  FETCH_ALL_METADATA_SUCCESS: 'FETCH_ALL_METADATA_SUCCESS',
  FETCH_ALL_METADATA_REJECT: 'FETCH_ALL_METADATA_REJECT'
}

//ACTION
//SIMDATA
const fetchSimDataRequest = () => {
  return {
    type: dataActions.FETCH_SIMDATA_REQUEST
  }
}

const fetchSimDataSuccess = simData => {
  return {
    type: dataActions.FETCH_SIMDATA_SUCCESS,
    simData: simData
  }
}

const fetchSimDataReject = error => {
  return {
    type: dataActions.FETCH_SIMDATA_REJECT,
    error: error
  }
}

//METADATA
const fetchMetadataRequest = () => {
  return {
    type: dataActions.FETCH_METADATA_REQUEST,
    metadata: null
  }
}

const fetchMetadataSuccess = metadata => {
  return {
    type: dataActions.FETCH_METADATA_SUCCESS,
    metadata: metadata
  }
}

const fetchMetadataReject = error => {
  return {
    type: dataActions.FETCH_METADATA_REJECT,
    error: error
  }
}

const clearMetadataRequest = () => {
  return {
    type: dataActions.CLEAR_METADATA
  }
}

//ALLMETADATA

const fetchAllMetadataRequest = () => {
  return {
    type: dataActions.FETCH_ALL_METADATA_REQUEST,
    metadata: null
  }
}

const fetchAllMetadataSuccess = allMetadata => {
  return {
    type: dataActions.FETCH_ALL_METADATA_SUCCESS,
    allMetadata: allMetadata
  }
}

const fetchAllMetadataReject = error => {
  return {
    type: dataActions.FETCH_ALL_METADATA_REJECT,
    error: error
  }
}

// REQUEST

export const fetchAllModels = () => {
  return dispatch => {
    dispatch(fetchAllMetadataRequest())
    get({
      path: `${config.gateway.baseUrl}/allMetadata`
    })
      .then(responseAllMetadata => {
        let prevObject = {}

        function propsData() {
          for (let i = 0; i < responseAllMetadata.length; i++) {
            const workingObject = prevObject
            const key = Object.keys(responseAllMetadata[i])
            const newObject = responseAllMetadata[i]
            buildObject(workingObject, key, newObject[key])
          }
        }

        function buildObject(workingObject, name, data) {
          const newObject = Object.assign({}, workingObject, {
            [name]: data
          })
          prevObject = newObject
        }

        propsData()
        dispatch(fetchAllMetadataSuccess(prevObject))
      })
      .catch(err => {
        dispatch(fetchAllMetadataReject(err))
      })
  }
}

export const fetchMetadata = modelName => {
  return dispatch => {
    dispatch(fetchMetadataRequest())
    get({
      path: `${config.gateway.baseUrl}/metadata?modelName=${modelName}`
    })
      .then(responseMetadata => {
        dispatch(fetchMetadataSuccess(responseMetadata))
      })
      .catch(err => {
        dispatch(fetchMetadataReject(err))
      })
  }
}

export const clearMetadata = () => {
  return dispatch => {
    dispatch(clearMetadataRequest())
  }
}

export const fetchSimData = (data, modelName) => {
  console.log(data)
  return dispatch => {
    dispatch(fetchSimDataRequest())
    post({
      path: `${config.gateway.baseUrl}/simulate?modelName=${modelName}`,
      body: data
    })
      .then(responseSimData => {
        console.log(responseSimData)
        const flux = Object.entries(responseSimData.flux)
        const concentrations = Object.entries(responseSimData.concentrations)
        const computed = Object.entries(responseSimData.computed)
        const timeData = responseSimData.time

        let prevObject = {}
        let i

        buildObject(prevObject, 'time', timeData)
        filterData(flux)
        filterData(concentrations)
        filterData(computed)

        function filterData(input) {
          for (i = 0; i < input.length; i++) {
            const working = input[i]
            const name = working[0]
            const data = working[1]
            const workingObject = prevObject
            buildObject(workingObject, name, data)
          }
        }

        function buildObject(workingObject, name, data) {
          const newObject = Object.assign({}, workingObject, {
            [name]: data
          })
          prevObject = newObject
        }
        dispatch(fetchSimDataSuccess(prevObject))
      })
      .catch(err => {
        dispatch(fetchSimDataReject(err))
      })
  }
}
