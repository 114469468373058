import React from 'react'
import { connect } from "react-redux";

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

class SimLinkCard extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log('-------------Card------------')
    console.log(this.props)
    const { history, data } = this.props

    return (
      <Card className="simLink">
        <CardActionArea onClick={() => history.push(`/simulator/${data}`)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Lizard
            </Typography>
            <Typography component="p">
              Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
          <Button size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      </Card>
    )
  }
}


//CONTAINER COMPONENTCollections
const mapStateToProps = (state) => {
    return {
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SimLinkCard);