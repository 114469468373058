import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
// import logo from '../logo.svg';
import './App.css'

import Header from './header/Header.jsx'
import Splash from './splash/splash.jsx'
import Simulator from './simulator/Simulator'
import Footer from './footer/Footer'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <main className="App-body">
          <Switch>
            <Route path="/" exact component={Splash} />
            <Route path="/simulator/:modelName" exact component={Simulator} />
          </Switch>
        </main>
        <Footer />
      </div>
    )
  }
}

export default App
