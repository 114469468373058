import React from 'react'
import { Link } from "react-router-dom";
// import { Router, Link } from 'react-static'
// import { hot } from 'react-hot-loader'

import './header.css'
import DownloadIcon from '@material-ui/icons/Description'

import logo from './images/logo.png'
import Button from '@material-ui/core/Button'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="main-header">
        <Link className="main-header__logo-container" to="/">
          <img className="main-header__logo" src={logo} alt="erythrodb" />
        </Link>
        <Button
          variant="contained"
          style={{ backgroundColor: '#33a169', fontSize: 10, height: '50px', alignSelf: 'center', margin: '20px' }}
          size="large"
          id="modelButton"
          onClick={() => window.open('https://s3.us-east-2.amazonaws.com/biosimulate/BioSimulate.white.paper.Dec2018.pdf')}
        >
          <DownloadIcon style={{ marginRight: 10 }} />
          Get the White Paper
        </Button>
        {/* </nav> */}
      </div>
    )
  }
}

export default Header
