import React from 'react'
import { connect } from 'react-redux'
// import { withSiteData, Router, Link, withRouteData } from 'react-static'

import SimLinkCard from '../lib/simLinkCard/simLinkCard'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import './splash.css'
import { fetchAllModels } from '../../lib/redux/entities/simulator/simulator.actions'

class Splash extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    console.log(this.props)
    console.log(this.state)
    const { dispatchFetchAllMetaData, simDataRoot } = this.props
    const { allMetadata } = this.props.simDataRoot
    if (!this.props.simDataRoot.allMetahasFetched && !this.props.simDataRoot.allMetaisFetching) {
      dispatchFetchAllMetaData()
    }

    return (
      <div className="splashPage">
        {this.props.simDataRoot.allMetahasFetched &&
          !this.props.simDataRoot.allMetaisFetching &&
          Object.entries(simDataRoot.allMetadata).map(input => {
            console.log(input)
            return <this.SimLinkCard simname={input[0]} data={input[1]} />
          })}
      </div>
    )
  }

  SimLinkCard = props => {
    console.log(props.simname)
    console.log(props.data)
    const { history } = this.props
    return (
      <Card className="simLink">
        <CardActionArea onClick={() => history.push(`/simulator/${props.data.info.model.id}`)}>
          <CardContent className='cardArea'>
            <img className={`image${props.simname}`} src={require(`./images/${props.simname}.png`)} alt="null" />
            <Typography gutterBottom className="cardDes" variant="h5" component="h2">
              {props.data.info.model.name}
            </Typography>

            <Typography className="cardDes" component="p">
              {props.data.info.model.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => history.push(`/simulator/${props.data.info.model.id}`)}>
            Run Simulation
          </Button>
        </CardActions>
      </Card>
    )
  }
}

// REDUX Container Component
const mapStateToProps = state => {
  return {
    simDataRoot: state.simDataRoot
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchFetchAllMetaData: () => dispatch(fetchAllModels())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Splash)
