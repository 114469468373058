import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter, Router } from "react-router-dom";
import { Router } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import createHashHistory from "history/createHashHistory";


import './index.css'

import App from './components/App.js';
// import registerServiceWorker from './registerServiceWorker';
import store from "./lib/redux/store";
import { Provider } from "react-redux";

//default with CRA
// ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

const customHistory = createBrowserHistory();
const hashHistory = createHashHistory()

ReactDOM.render(
  <Provider store={store}>
    <Router history={hashHistory}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("app")
);