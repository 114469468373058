import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import rootReducer from './rootReducer'

const store = createStore(
  rootReducer,
  applyMiddleware(thunk,
    createLogger({ collapsed: true })
  )
)

export default store
