import React from 'react'
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Label, CartesianGrid, ScatterChart, Scatter, ZAxis } from 'recharts'
//
// import randomColor from 'randomcolor'

import Timer from 'react-compound-timer'

// import { scaleLog } from 'd3-scale'

import { CSVLink } from 'react-csv'

//Material UI
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import SimulateIcon from '@material-ui/icons/Restore'

import Clear from '@material-ui/icons/Clear'
import Done from '@material-ui/icons/Done'
import Search from '@material-ui/icons/Search'
import Remove from '@material-ui/icons/Remove'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import ListItemText from '@material-ui/core/ListItemText'

import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

import './simulator.css'
import { fetchSimData, fetchMetadata, clearMetadata } from '../../lib/redux/entities/simulator/simulator.actions'

import {
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  Select,
  MenuItem,
  FormGroup,
  TableBody,
  TableRow,
  TableCell,
  Table
} from '@material-ui/core'
// import { Loading } from 'react-static';

class Simulator extends React.Component {
  componentDidMount() {
    const modelName = this.props.match.params.modelName
    const { dispatchFetchMetadata } = this.props
    dispatchFetchMetadata(modelName)
  }

  constructor(props) {
    super(props)
    this.state = {
      hasUpdated: false,
      concentrationXAxis: 'Linear',
      concentrationYAxis: 'Linear',
      ratesXAxis: 'Linear',
      ratesYAxis: 'Linear',
      metadataFetched: false,
      modelExpand: true,
      simExpand: true,
      resultsExpand: true,
      concentrationsPlotHeight: 300,
      ratesPlotHeight: 300,
      phasePlotHeight: 600,

      GlycHB: {
        selectedConcentrations: {
          glc__D_c: true,
          atp_c: true
        },
        ratesSelected: {
          HEX1: true,
          PFK: true
        },
        concentrationstoPlot: {
          glc__D_c: true
        },
        ratestoPlot: {
          HEX1: true
        },

        glc_pulse: 50,
        kf_atp: 0,
        kf_pfk: 0,
        atp_pulse: 0,
        t0: '0',
        t_end: '10',
        'ATP utilization': true,
        'Intracellular D-Glucose': true,
        'PFK rate': true,
        'Intracellular ATP': true,
        phasesToPlotXAxis: 'HEX1',
        phasesToPlotYAxis: 'PFK',
        phasePlotData: null,
        modelDownload: 'SBML',
        currentModel: 'glycolysis',
        choosenMetabolite: 'Metabolite (chosen from dropdown)'
      },
      'Michaelis-Menten': {
        selectedConcentrations: {
          s: true,
          e: true
        },
        ratesSelected: {
          v1: true,
          v2: true
        },
        concentrationstoPlot: {
          s: true
        },
        ratestoPlot: {
          v1: true
        },
        b1_f: 0,
        b2_f: 0,
        e0: 0.1,
        es0: 0,
        k1_eq: 2,
        k1_f: 2,
        k2_f: 1,
        p0: 0,
        s0: 1,
        'System input (b1_f)': true,
        'System output (b2_f)': true,
        'Enzyme (E) concentration': true,
        'Intermediate (ES) concentration': true,
        'Enzyme-substrate equilibrium constant (k1_eq)': true,
        'Enzyme-substrate forward rate constant (k1_f)': true,
        'Product conversion rate constant (k2_f)': true,
        'Product (P) concentration': true,
        'Substrate (S) concentration': true,
        t0: '0',
        t_end: '100',
        phasesToPlotXAxis: 'HEX1',
        phasesToPlotYAxis: 'PFK',
        phasePlotData: null,
        modelDownload: 'SBML',
        currentModel: 'glycolysis',
        choosenMetabolite: 'Metabolite (chosen from dropdown)'
      }
    }
  }

  handleClickModel = () => {
    this.setState(state => ({ modelExpand: !state.modelExpand }))
  }

  handleClickSim = () => {
    this.setState(state => ({ simExpand: !state.simExpand }))
  }

  handleClickResults = () => {
    this.setState(state => ({ resultsExpand: !state.resultsExpand }))
  }

  intialDropDowns = (data, name, type) => {
    const keys = Object.keys(data.info[type])
    const value = keys[0]
    const nextState = {}
    nextState[name] = value
    this.setState(nextState)
  }

  render() {
    const { dispatchClearMetadata } = this.props
    console.log(this.state)
    const { simDataRoot } = this.props
    console.log(this.props)
    const paramName = this.props.match.params.modelName
    const dropDownData = simDataRoot.metadata[paramName]
    if (!this.state.metadataFetched) {
      dispatchClearMetadata()
      this.setState({
        metadataFetched: true
      })
    }

    {
      this.state.dataModel && !this.state.csvHeaders && this.createCSVHeaders()
    }

    if (
      this.props.simDataRoot.simhasFetched === true &&
      this.props.simDataRoot.metahasFetched === true &&
      this.state.hasUpdated === false &&
      this.state.dataModel === null &&
      this.props.simDataRoot.simisFetching === false &&
      !this.props.simDataRoot.error
    ) {
      this.initalData(simDataRoot.simData)
    }

    {
      simDataRoot.metahasFetched &&
        !this.state.reactionMetaDisplay &&
        dropDownData &&
        this.intialDropDowns(dropDownData, 'reactionMetaDisplay', 'reaction')
    }

    {
      simDataRoot.metahasFetched &&
        !this.state.metaboliteMetaDisplay &&
        dropDownData &&
        this.intialDropDowns(dropDownData, 'metaboliteMetaDisplay', 'metabolite')
    }

    let concentrations
    let rates
    let inputs
    const selectedConcentrations = this.state[paramName].selectedConcentrations
    const valuet0 = this.state[paramName].t0
    const valuet_end = this.state[paramName].t_end

    if (this.props.simDataRoot.metahasFetched === true && !this.props.simDataRoot.metaisFetching && this.state.metadataFetched) {
      concentrations = this.props.simDataRoot.metadata[paramName].outputs.concentrations
      rates = this.props.simDataRoot.metadata[paramName].outputs.rates
      inputs = this.props.simDataRoot.metadata[paramName].inputs
    }

    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })

    if (this.props.simDataRoot.metaisFetching) {
      console.log(this.props.simDataRoot.metaisFetching)
      return (
        <div className="loading">
          <CircularDeterminate />
        </div>
      )
    }
    return (
      <div className="simulator-body">
        <div className="mainDashboardAll">
          <div className="simulationTitle" onClick={this.handleClickModel}>
            <div className="expandButton" 
            // style={{ marginTop: 30 }}
            >
              {this.state.modelExpand ? <RemoveCircle id="expand" iconStyle={{ height: '100px' }} /> : <AddCircle id="expand" />}
            </div>
            <h1 className='cardTitles' 
            // style={{ paddingBottom: 0, marginBottom: 0, paddingTop: 0 }}
            >
              Model Dashboard <span style={{ color: 'gray' }}> | </span>
              <span style={{ color: 'gray', fontWeight: 100 }}>
                {(simDataRoot.metahasFetched && dropDownData && dropDownData.info.model.name) || 'Loading...'}
              </span>
            </h1>
          </div>
          <Collapse in={this.state.modelExpand} timeout="auto" unmountOnExit>
            <div style={{ paddingTop: 5, paddingLeft: 80, marginBottom: 20, textAlign: 'left', width: '60%'}}>
              <div>{(simDataRoot.metahasFetched && dropDownData && dropDownData.info.model.description) || 'Loading...'}</div>
            </div>
            <div id="plotOptions" style={{ justifyContent: 'flex-end' }}>
              {/* <Button
                variant="contained"
                style={{ backgroundColor: '#2e6be6' }}
                size="large"
                id="modelButton"
                onClick={() => window.open('https://www.ebi.ac.uk/biomodels/model/download/BIOMD0000000428.2?filename=BIOMD0000000428_url.xml')}
              >
                <DownloadIcon style={{ marginRight: 10 }} />
                Download Model
              </Button> */}
              <div style={{ display: 'none' }}>
                <MuiThemeProvider theme={outerTheme}>
                  <RadioGroup
                    aria-label="modelDownload"
                    name="modelDownload"
                    value={this.state.modelDownload}
                    onChange={this.handleChangeRadio}
                    id="radioGroup"
                  >
                    <FormControlLabel value="SBML" control={<Checkbox />} label="SBML" />
                    {/* <FormControlLabel value="JSON" control={<Checkbox />} label="JSON" /> */}
                  </RadioGroup>
                </MuiThemeProvider>
              </div>
            </div>
            <div className="mainDashboard">
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      MODEL
                    </Typography>
                    {simDataRoot.metahasFetched &&
                      this.state.metadataFetched &&
                      this.renderModelCard(simDataRoot.metadata[paramName].info.model.info)}
                  </div>
                </CardContent>
              </Card>
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      METABOLITE
                    </Typography>
                    <div className="plotOptions-row">
                      <Select
                        value={this.state.metaboliteMetaDisplay}
                        onChange={this.handleChangeRadio}
                        input={<OutlinedInput name="metaboliteMetaDisplay" id="outlined-age-simple" />}
                        autoWidth
                      >
                        {simDataRoot.metahasFetched &&
                          this.state.metadataFetched &&
                          Object.keys(simDataRoot.metadata[paramName].info.metabolite).map(input => {
                            const name = simDataRoot.metadata[paramName].info.metabolite[input].name
                            return <MenuItem value={input}>{name}</MenuItem>
                          })}
                      </Select>
                    </div>
                    {simDataRoot.metahasFetched &&
                      this.state.metaboliteMetaDisplay &&
                      this.renderMetaboliteCard(this.state.metaboliteMetaDisplay, paramName)}
                  </div>
                </CardContent>
              </Card>
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      REACTION
                    </Typography>
                    <div className="plotOptions-row">
                      <Select
                        value={this.state.reactionMetaDisplay}
                        onChange={this.handleChangeRadio}
                        input={<OutlinedInput name="reactionMetaDisplay" id="outlined-age-simple" />}
                        autoWidth
                      >
                        {simDataRoot.metahasFetched &&
                          this.state.metadataFetched &&
                          Object.keys(simDataRoot.metadata[paramName].info.reaction).map(input => {
                            const name = simDataRoot.metadata[paramName].info.reaction[input].name
                            return <MenuItem value={input}>{name}</MenuItem>
                          })}
                      </Select>
                    </div>
                    {simDataRoot.metahasFetched &&
                      this.state.reactionMetaDisplay &&
                      this.renderReactionCard(this.state.reactionMetaDisplay, paramName)}
                  </div>
                </CardContent>
              </Card>
            </div>
          </Collapse>
        </div>

        <div className="mainDashboardAll">
          <div className="simulationTitle" onClick={this.handleClickSim}>
            <div className="expandButton">
              {this.state.simExpand ? <RemoveCircle id="expand" iconStyle={{ height: '100px' }} /> : <AddCircle id="expand" />}
            </div>
            <h1 className='cardTitles' >Simulation Dashboard</h1>
          </div>
          <Collapse in={this.state.simExpand} timeout="auto" unmountOnExit>
            <div className="mainDashboard">
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      INPUTS
                    </Typography>
                    <Typography component="p" variant="p" gutterBottom>
                      Parameters for simulation. Entering values other than zero represent a perturbation of the system (all zeros is the baseline
                      behavior).
                    </Typography>
                    {inputs &&
                      Object.entries(inputs).map(input => {
                        var inputData = input[1]
                        var inputJsonName = input[0].toString()
                        // const state= this.state
                        return <this.inputParameters data={inputData} jsonName={inputJsonName} state={this.state} />
                      })}
                  </div>
                </CardContent>
              </Card>
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      SIMULATION PARAMETERS
                    </Typography>
                    <Typography component="p" variant="p" gutterBottom>
                      General Simulation parameters and settings.
                    </Typography>

                    <div id="timeInput">
                      <FormControl className="border">
                        <OutlinedInput
                          id="input-with-icon-adornment"
                          name="t0"
                          value={this.state[paramName].t0}
                          onChange={this.handleChangeTime.bind(this)}
                          placeholder="Time Start"
                        />
                      </FormControl>
                      <Remove className="timeLabels" />
                      <FormControl className="border">
                        <OutlinedInput
                          id="input-with-icon-adornment"
                          name="t_end"
                          value={this.state[paramName].t_end}
                          onChange={this.handleChangeTime.bind(this)}
                          placeholder="Time End"
                        />
                      </FormControl>
                      <Typography className="timeLabels">hours</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="plotOptions3">
                <CardContent>
                  <div className="parameters">
                    <Typography component="h5" variant="h5" gutterBottom>
                      OUTPUTS
                    </Typography>
                    <Typography component="p" variant="p" gutterBottom>
                      Select the outputs for which the results will be visualized.
                    </Typography>
                    <Typography component="h6" variant="h6" gutterBottom>
                      Concentrations
                    </Typography>
                    {concentrations &&
                      concentrations.map(input => {
                        return <this.outputParametersConcentrations data={input} />
                      })}
                    <Typography component="h6" variant="h6" gutterBottom>
                      Rates
                    </Typography>
                    {rates &&
                      rates.map(input => {
                        return <this.outputParametersRates data={input} />
                      })}
                  </div>
                </CardContent>
              </Card>
            </div>
            <div id="plotOptions">
              <Button
                variant="contained"
                size="large"
                id="simButton"
                onClick={this.submit.bind(this)}
                style={{
                  marginTop: 30,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontSize: 35
                }}
              >
                <SimulateIcon size="large" style={{ marginRight: 10, fontSize: 30 }} />
                SIMULATE
              </Button>
            </div>
          </Collapse>
        </div>

        <div className={this.state.hasUpdated ? 'simulationsContainer' : 'hidden'}>
          {/* <div className="simulationsContainer"> */}
          <div className="simulationTitle">
            <div className="simulationTitle_Left" onClick={this.handleClickResults}>
              <div className="expandButton">
                {this.state.resultsExpand ? <RemoveCircle id="expand" iconStyle={{ height: '100px' }} /> : <AddCircle id="expand" />}
              </div>
              <h1 className='cardTitles' >Results Dashboard</h1>
            </div>
            <div className="simulationTitle_Right">
              <div className="csvDownload">
                {this.state.dataModel && (
                  <CSVLink
                    data={this.state.dataModel}
                    headers={this.state.csvHeaders}
                    style={{ textDecoration: 'none', justifySelf: 'flex-end' }}
                    filename={`bioSimulateData_${this.props.match.params.modelName}.csv`}
                  >
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#33a169', fontSize: 10, height: '50px', alignSelf: 'center', color: 'white' }}
                      size="large"
                      id="modelButton"
                    >
                      <DownloadIcon style={{ marginRight: 10 }} />
                      Download CSV
                    </Button>
                  </CSVLink>
                )}
              </div>
            </div>
          </div>
          <Collapse in={this.state.resultsExpand} timeout="auto" unmountOnExit>
            <h3>Concentrations</h3>
            <div className="chartContainers">
              <div className="chartSubContainers">
                <this.concentrationsCharts />
              </div>
              <div className="selectionPane">
                <Card className="plotOptions2">
                  <CardContent>
                    {/* <Typography gutterBottom>PLOT OPTIONS</Typography> */}
                    <this.concentrationsplotOptions name="concentration" XAxis="concentrationXAxis" YAxis="concentrationYAxis" />
                  </CardContent>
                </Card>
              </div>
            </div>
            <h3>Reaction Flux</h3>
            <div className="chartContainers">
              <div className="chartSubContainers">
                <this.ratesCharts />
              </div>
              <div className="selectionPane">
                <Card className="plotOptions2">
                  <CardContent>
                    <this.ratesplotOptions name="rates" XAxis="ratesXAxis" YAxis="ratesYAxis" />
                  </CardContent>
                </Card>
              </div>
            </div>
            <h3>Phase Portrait</h3>
            <div className="chartContainers">
              <div className="chartSubContainers">
                <this.phaseCharts />
              </div>
              <div className="selectionPane">
                <Card className="plotOptions2">
                  <CardContent>
                    <this.phaseplotOptions name="phase" XAxis="phaseXAxis" YAxis="phaseYAxis" />
                  </CardContent>
                </Card>
              </div>
            </div>
          </Collapse>
        </div>
        <SimpleDialog open={this.props.simDataRoot.simisFetching} onClose={this.handleClose} />
      </div>
    )
  }

  // Material UI Components

  formatModules = data => {
    data = data.replace('{', '')
    data = data.replace('}', '')
    data = data.replace(/'/g, '')
    return data
  }

  renderModelCard = data => {
    const formattedModules = this.formatModules(data.modules.value)
    return (
      <Table padding="default">
        <TableBody>
          <TableRow>
            <TableCell>Number of metabolites</TableCell>
            <TableCell>{data.num_mets.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number of reactions</TableCell>
            <TableCell>{data.num_rxns.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Dimension of S matrix</TableCell>
            <TableCell>{data.s_dim.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rank of S matrix</TableCell>
            <TableCell>{data.s_rank.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Modules in model</TableCell>
            <TableCell>{formattedModules}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  renderMetaboliteCard = (data, paramName) => {
    const { metabolite } = this.props.simDataRoot.metadata[paramName].info
    const metaboliteData = metabolite[data]
    const formattedArray = this.formatArray(metaboliteData.info.rxns.value)
    return (
      <Table padding="default">
        <TableBody>
          <TableRow>
            <TableCell>Metabolite name</TableCell>
            <TableCell>{metaboliteData.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Metabolite ID</TableCell>
            <TableCell>{metaboliteData.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Metabolite formula</TableCell>
            <TableCell>{metaboliteData.info.formula.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Metabolite weight</TableCell>
            <TableCell>{metaboliteData.info.weight.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Reactions in which metabolite participates</TableCell>
            <TableCell>{formattedArray}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  formatArray = data => {
    let newString = ''
    for (let i = 0; i < data.length; i++) {
      const newValue = data[i]
      if (i === 0) {
        let tempString = newValue
        newString = tempString
      } else {
        let tempString = newString + ', ' + newValue
        newString = tempString
      }
    }
    return newString
  }

  renderReactionCard = (data, paramName) => {
    const { reaction } = this.props.simDataRoot.metadata[paramName].info
    const reactionData = reaction[data]
    const formattedArray = this.formatArray(reactionData.info.mets.value)
    return (
      <Table padding="default">
        <TableBody>
          <TableRow>
            <TableCell>Reaction name</TableCell>
            <TableCell>{reactionData.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Reaction ID</TableCell>
            <TableCell>{reactionData.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Reaction formula</TableCell>
            <TableCell>{reactionData.info.formula.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Products and reactants</TableCell>
            <TableCell>{formattedArray}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  ratesLines = data => {
    return <Line type="monotone" dataKey="GLC" stroke="#8884d8" />
  }

  inputParameters = data => {
    const props = data.data
    const paramName = this.props.match.params.modelName
    const modelState = this.state[paramName]
    // const name = props.name
    const defaultValue = modelState[data.jsonName]
    const jsonName = modelState[props.name]
    return (
      <div className="input-box">
        <div className="parameter-name">{props.name} </div>

        <Typography gutterBottom>{props.description}</Typography>
        <FormControl margin="none">
          <TextField
            defaultValue={defaultValue}
            id={data.jsonName}
            type="number"
            name={props.name}
            value={defaultValue}
            onChange={this.handleChange.bind(this)}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment id="endAdornment" position="end" disableTypography="true">
                  {data.data.unit}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {!jsonName && jsonName != null && <Clear style={{ color: 'red' }} />}
                  {jsonName && <Done style={{ color: '#28FF49' }} />}
                </InputAdornment>
              )
            }}
          />

          {!jsonName && (
            <FormHelperText id="component-helper-text">
              Input must be between {props.min} and {props.max}
            </FormHelperText>
          )}
          {jsonName && <FormHelperText id="component-helper-text" />}
        </FormControl>
      </div>
    )
  }

  createCSVHeaders = () => {
    const data = this.state.dataModel[0]
    const headers = []
    Object.keys(data).map((oKey, i) => {
      const key = oKey
      let label
      const concentrations = this.props.simDataRoot.metadata[this.props.match.params.modelName].outputs.concentrations
      const rates = this.props.simDataRoot.metadata[this.props.match.params.modelName].outputs.rates
      for (let i = 0; i < concentrations.length; i++) {
        if (concentrations[i].variable === oKey) {
          label = concentrations[i].id
        }
      }
      if (!label) {
        for (let i = 0; i < rates.length; i++) {
          if (rates[i].variable === oKey) {
            label = rates[i].id
          }
        }
      }
      if (!label) {
        label = 'Time'
      }
      headers.push({ label: label, key: key })
    })
    this.setState({
      csvHeaders: headers
    })
  }

  outputParametersConcentrations = data => {
    const paramName = this.props.match.params.modelName
    const checked = this.state[paramName].selectedConcentrations[data.data.variable]
    console.log(checked)
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })

    return (
      <div className="output-box">
        <MuiThemeProvider theme={outerTheme}>
          <Checkbox checked={checked} onChange={this.concentrationsCheckbox(data.data.variable)} value={data.data.id} />
        </MuiThemeProvider>
        <div className="output-box_des">
          <Typography gutterBottom>{data.data.name}</Typography>
          <Typography gutterBottom>{data.data.description}</Typography>
        </div>
      </div>
    )
  }

  outputParametersRates = data => {
    const paramName = this.props.match.params.modelName
    const checked = this.state[paramName].ratesSelected[data.data.variable]
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })

    return (
      <div className="output-box">
        <MuiThemeProvider theme={outerTheme}>
          <Checkbox checked={checked} onChange={this.ratesCheckbox(data.data.variable)} />
        </MuiThemeProvider>
        <div className="output-box_des">
          <Typography gutterBottom>{data.data.name}</Typography>
          <Typography gutterBottom>{data.data.description}</Typography>
        </div>
      </div>
    )
  }

  signalsToPlot = data => {
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })

    const concentrations = this.props.simDataRoot.metadata[this.props.match.params.modelName].outputs.concentrations
    const rates = this.props.simDataRoot.metadata[this.props.match.params.modelName].outputs.rates
    const name = `${data.data[0]}`
    const concentrationscheckedValue = this.state[this.props.match.params.modelName].concentrationstoPlot[name]
    const ratescheckedValue = this.state[this.props.match.params.modelName].ratestoPlot[name]
    const arrayToObject = array =>
      array.reduce((obj, item) => {
        obj[item.variable] = item
        return obj
      }, {})

    const concentrationsObject = arrayToObject(concentrations)
    const ratesObject = arrayToObject(rates)

    const hydratedConcentration = concentrationsObject[data.data[0]]
    const hydratedRates = ratesObject[data.data[0]]

    if (hydratedConcentration) {
      if (!this.state.concentrationsInitialCheck) {
        this.setState({ concentrationsInitialCheck: true })
        this.concentrationsCheckboxPlots(name)
      }
      return (
        <div className="output-box">
          <MuiThemeProvider theme={outerTheme}>
            <Checkbox checked={concentrationscheckedValue} onChange={this.concentrationsCheckboxPlots(name)} value={name} id="checkBox" />
          </MuiThemeProvider>
          <div className="output-box_des">
            <Typography gutterBottom>{hydratedConcentration.name}</Typography>
          </div>
        </div>
      )
    }
    if (hydratedRates) {
      const defaultChecked = this.state[this.props.match.params.modelName].ratestoPlot[name]
      return (
        <div className="output-box">
          <MuiThemeProvider theme={outerTheme}>
            <Checkbox
              checked={this.state[this.props.match.params.modelName].ratestoPlot[name]}
              onChange={this.ratesCheckboxPlots(name)}
              value={name}
              defaultChecked={defaultChecked}
            />
          </MuiThemeProvider>
          <div className="output-box_des">
            <Typography gutterBottom>{hydratedRates.name}</Typography>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  // Components
  concentrationsCharts = props => {
    const concentrationstoPlot = this.state[this.props.match.params.modelName].concentrationstoPlot
    const colors = ['#8B0304', '#0070B4', '#A52963', '#2B388C', '#8D3289', '#067381', '#00AD73', '#00A1B7']
    // const scale = scaleLog().base(Math.E)
    return (
      <ResponsiveContainer height={this.state.concentrationsPlotHeight}>
        <LineChart data={this.state.dataModel} style={{ overflow: 'unset' }} syncId="metaProfiles">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" tickFormatter={number => this.dataFormaterTime(number)} scale={this.state.concentrationXAxis} minTickGap={25}>
            <Label value="Time (hours)" offset={0} position="bottom" />
          </XAxis>
          <YAxis
            domain={[dataMin => dataMin * 0.95, dataMax => dataMax * 1.05]}
            label={{
              value: 'Concentration  (mmol)',
              position: 'left',
              angle: -90,
              dx: -10,
              dy: -70
            }}
            tickFormatter={number => this.dataFormaterY(number)}
            scale={this.state.concentrationYAxis}
            //
          />
          <Tooltip formatter={number => this.dataFormaterY(number)} labelFormatter={name => 'Time (hours): ' + this.dataFormaterTime(name)} />
          {concentrationstoPlot &&
            Object.entries(concentrationstoPlot).map((input, i) => {
              if (input[1] === true) {
                const color = colors[i]
                return <Line type="monotone" dataKey={input[0]} stroke={color} dot={false} />
              }
            })}
        </LineChart>
      </ResponsiveContainer>
    )
  }

  cartLines = data => {
    let timeLines = []
    for (let i = 0; i < data.length; i = i + 5) {
      timeLines.push(i)
    }
    return timeLines
  }

  ratesCharts = props => {
    const ratestoPlot = this.state[this.props.match.params.modelName].ratestoPlot
    const colors = ['#067381', '#A52963', '#8D3289', '#8B0304', '#00AD73', '#0070B4', '#2B388C', '#00A1B7']
    return (
      <ResponsiveContainer height={this.state.ratesPlotHeight}>
        <LineChart data={this.state.dataModel} style={{ overflow: 'unset' }} syncId="metaProfiles">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            tickFormatter={number => this.dataFormaterTime(number)}
            scale={this.state.ratesXAxis}
            // scale={scale}
            domain={[0.00000001, 'auto']}
            allowDataOverflow
            minTickGap={10}
          >
            <Label value="Time (hours)" offset={0} position="bottom" />
          </XAxis>
          <YAxis
            domain={[dataMin => dataMin * 0.95, dataMax => dataMax * 1.05]}
            label={{
              value: 'Flux (mmol/hour)',
              position: 'left',
              angle: -90,
              dx: -10,
              dy: -50
            }}
            tickFormatter={number => this.dataFormaterY(number)}
            scale={this.state.ratesYAxis}
          />
          <Tooltip formatter={number => this.dataFormaterY(number)} labelFormatter={name => 'Time (hours): ' + this.dataFormaterTime(name)} />
          {ratestoPlot &&
            Object.entries(ratestoPlot).map((input, i) => {
              if (input[1] === true) {
                const color = colors[i]
                return <Line type="monotone" dataKey={input[0]} stroke={color} dot={false} />
              }
            })}
        </LineChart>
      </ResponsiveContainer>
    )
  }

  phaseCharts = props => {
    // phasesToPlot = this.state.phasesToPlot
    const data = this.state.dataModel
    const yAxisValue = this.state.phasesToPlotYAxis

    if (this.state.hasUpdated && this.state.phasesToPlotXAxis != null && this.state.phasesToPlotYAxis != null && !this.state.phasePlotData) {
      this.phaseData(this.props.simDataRoot.simData, this.state.phasesToPlotYAxis, this.state.phasesToPlotXAxis)
    }
    const phasesToPlot = this.state.phasePlotData
    return (
      <ScatterChart width={this.state.phasePlotHeight} height={this.state.phasePlotHeight}>
        <CartesianGrid />
        <XAxis type="number" dataKey={this.state.phasesToPlotXAxis} tickFormatter={number => this.dataFormaterY(number)} domain={['auto', 'auto']}>
          <Label value={this.state.phasesToPlotXAxis} offset={0} position="bottom" />
        </XAxis>
        <YAxis
          type="number"
          dataKey={this.state.phasesToPlotYAxis}
          tickFormatter={number => this.dataFormaterY(number)}
          domain={['auto', 'auto']}
          label={{
            value: yAxisValue,
            position: 'left',
            angle: -90,
            dx: -10,
            dy: -50
          }}
        />
        <ZAxis dataKey='time' />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={number => this.dataFormaterY(number)} />
        <Scatter name="A school" data={data} fill="#00A1B7" line />
      </ScatterChart>
    )
  }

  bigButton = props => {
    const ischecked = `this.state.${props.name}`
    return (
      <div className="buttonRow">
        <input name={props.name} type="checkbox" onChange={this.hidden.bind(this)} checked={props.checked} />
        <h4>{props.displayName}</h4>
      </div>
    )
  }

  concentrationsplotOptions = props => {
    const paramName = this.props.match.params.modelName
    const selectedConcentrations = this.state[paramName].selectedConcentrations
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })
    return (
      <div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">PLOT OPTIONS</h4>
            <div className="plotOptions">
              <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>X Axis</h4>
                  <MuiThemeProvider theme={outerTheme}>
                    <RadioGroup
                      aria-label={props.name}
                      name={props.XAxis}
                      value={this.state[props.XAxis]}
                      onChange={this.handleChangeRadio}
                      id="radioGroup"
                    >
                      <FormControlLabel value="Linear" control={<Checkbox />} label="Linear" />
                      <FormControlLabel value="Logarithmic" control={<Checkbox />} label="Logarithmic" />
                    </RadioGroup>
                  </MuiThemeProvider>
                </div>
              </div>

              {/* <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>Y Axis</h4>
                  <RadioGroup
                    aria-label={props.name}
                    name={props.YAxis}
                    value={this.state[props.YAxis]}
                    onChange={this.handleChangeRadio}
                    id="radioGroup"
                  >
                    <FormControlLabel value="Linear" control={<Checkbox />} label="Linear" />
                    <FormControlLabel value="Logarithmic" control={<Checkbox />} label="Logarithmic" />
                  </RadioGroup>
                </div>
              </div> */}
              <div className="plotHeightSelect">
                <FormControl>
                  {/* <Select value={this.state.concentrationsPlotHeight} onChange={this.handleChangeRadio} name="concentrationsPlotHeight"> */}
                  <FormHelperText>Plot Height</FormHelperText>
                  <Select
                    value={this.state.concentrationsPlotHeight}
                    onChange={this.handleChangeRadio}
                    input={<OutlinedInput name="concentrationsPlotHeight" id="outlined-age-simple" />}
                  >
                    <MenuItem value={200}>200 pixels</MenuItem>
                    <MenuItem value={300}>300 pixels</MenuItem>
                    <MenuItem value={400}>400 pixels</MenuItem>
                    <MenuItem value={500}>500 pixels</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">SIGNALS TO PLOT</h4>
            {selectedConcentrations &&
              this.state.hasUpdated &&
              Object.entries(selectedConcentrations).map(input => {
                if (input[1] === true) {
                  return <this.signalsToPlot data={input} />
                }
              })}
          </div>
        </div>
      </div>
    )
  }

  ratesplotOptions = props => {
    const ratesSelected = this.state[this.props.match.params.modelName].ratesSelected
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })

    return (
      <div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">PLOT OPTIONS</h4>
            <div className="plotOptions">
              <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>X Axis</h4>
                  <MuiThemeProvider theme={outerTheme}>
                    <RadioGroup
                      aria-label={props.name}
                      name={props.XAxis}
                      value={this.state[props.XAxis]}
                      onChange={this.handleChangeRadio}
                      id="radioGroup"
                    >
                      <FormControlLabel value="Linear" control={<Checkbox />} label="Linear" />
                      <FormControlLabel value="Logarithmic" control={<Checkbox />} label="Logarithmic" />
                    </RadioGroup>
                  </MuiThemeProvider>
                </div>
              </div>

              {/* <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>Y Axis</h4>

                  <RadioGroup
                    aria-label={props.name}
                    name={props.YAxis}
                    value={this.state[props.YAxis]}
                    onChange={this.handleChangeRadio}
                    id="radioGroup"
                  >
                    <FormControlLabel value="Linear" control={<Checkbox />} label="Linear" />
                    <FormControlLabel value="Logarithmic" control={<Checkbox />} label="Logarithmic" />
                  </RadioGroup>
                </div>
              </div> */}
              <div className="plotHeightSelect">
                <FormControl>
                  {/* <Select value={this.state.concentrationsPlotHeight} onChange={this.handleChangeRadio} name="concentrationsPlotHeight"> */}
                  <FormHelperText>Plot Height</FormHelperText>
                  <Select
                    value={this.state.ratesPlotHeight}
                    onChange={this.handleChangeRadio}
                    input={<OutlinedInput name="ratesPlotHeight" id="outlined-age-simple" />}
                  >
                    <MenuItem value={200}>200 pixels</MenuItem>
                    <MenuItem value={300}>300 pixels</MenuItem>
                    <MenuItem value={400}>400 pixels</MenuItem>
                    <MenuItem value={500}>500 pixels</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">SIGNALS TO PLOT</h4>
            {ratesSelected &&
              this.state.hasUpdated &&
              Object.entries(ratesSelected).map(input => {
                if (input[1] === true) {
                  return <this.signalsToPlot data={input} />
                }
              })}
          </div>
        </div>
      </div>
    )
  }

  phaseplotOptions = props => {
    const ratesSelected = this.state[this.props.match.params.modelName].ratesSelected
    const outerTheme = createMuiTheme({
      palette: {
        secondary: {
          main: '#33a169'
        }
      },
      typography: { useNextVariants: true }
    })
    return (
      <div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">PLOT OPTIONS</h4>
            <div className="plotOptions">
              {/* <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>X Axis</h4>
                  <MuiThemeProvider theme={outerTheme}>
                    <RadioGroup
                      aria-label={props.name}
                      name={props.XAxis}
                      value={this.state[props.XAxis]}
                      onChange={this.handleChangeRadio}
                      id="radioGroup"
                    >
                      <FormControlLabel
                        value="Linear"
                        control={<Checkbox />}
                        label="Linear"
                      />
                      <FormControlLabel
                        value="Logarithmic"
                        control={<Checkbox />}
                        label="Logarithmic"
                      />
                    </RadioGroup>
                  </MuiThemeProvider>
                </div>
              </div> */}

              {/* <div className="plotOptions-row">
                <div className="fixFormat">
                  <h4>Y Axis</h4>

                  <RadioGroup
                    aria-label={props.name}
                    name={props.YAxis}
                    value={this.state[props.YAxis]}
                    onChange={this.handleChangeRadio}
                    id="radioGroup"
                  >
                    <FormControlLabel value="Linear" control={<Checkbox />} label="Linear" />
                    <FormControlLabel value="Logarithmic" control={<Checkbox />} label="Logarithmic" />
                  </RadioGroup>
                </div>
              </div> */}
              <div className="plotHeightSelect">
                <FormControl>
                  {/* <Select value={this.state.concentrationsPlotHeight} onChange={this.handleChangeRadio} name="concentrationsPlotHeight"> */}
                  <FormHelperText>Plot Height</FormHelperText>
                  <Select
                    value={this.state.phasePlotHeight}
                    onChange={this.handleChangeRadio}
                    input={<OutlinedInput name="phasePlotHeight" id="outlined-age-simple" />}
                  >
                    <MenuItem value={300}>300 pixels</MenuItem>
                    <MenuItem value={400}>400 pixels</MenuItem>
                    <MenuItem value={500}>500 pixels</MenuItem>
                    <MenuItem value={600}>600 pixels</MenuItem>
                    <MenuItem value={700}>700 pixels</MenuItem>
                    <MenuItem value={800}>800 pixels</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="optionsContainers">
          <div>
            <h4 className="plotOptionsTitle">SIGNALS TO PLOT</h4>
            <div className="plotOptions-row">
              <div className="fixFormat2">
                <h4>X Axis</h4>
                <Select
                  value={this.state.phasesToPlotXAxis}
                  onChange={this.handleChangeRadio}
                  input={<OutlinedInput name="phasesToPlotXAxis" id="outlined-age-simple" />}
                  autoWidth
                >
                  {this.state.hasUpdated &&
                    Object.keys(this.props.simDataRoot.simData).map(input => {
                      return <MenuItem value={input}>{input}</MenuItem>
                    })}
                </Select>
              </div>
            </div>
            <div className="plotOptions-row">
              <div className="fixFormat2">
                <h4>Y Axis</h4>
                <Select
                  value={this.state.phasesToPlotYAxis}
                  onChange={this.handleChangeRadio}
                  input={<OutlinedInput name="phasesToPlotYAxis" id="outlined-age-simple" />}
                  autoWidth
                >
                  {this.state.hasUpdated &&
                    Object.keys(this.props.simDataRoot.simData).map(input => {
                      return <MenuItem value={input}>{input}</MenuItem>
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Actions

  checkValue(data, value) {
    const paramName = this.props.match.params.modelName
    let name = data.id
    const props = this.props.simDataRoot.metadata[paramName].inputs[name]
    if (Number.isInteger(value) === true) {
      if (props.min <= value && value <= props.max) {
        const name = data.name
        const value = true
        const nextState = {}
        nextState[name] = value
        const stateToSet = Object.assign({}, this.state[paramName], {
          [name]: value
        })
        return { [paramName]: stateToSet }
      } else {
        const name = data.name
        const value = false
        const nextState = {}
        nextState[name] = value
        const stateToSet = Object.assign({}, this.state[paramName], {
          [name]: value
        })
        console.log(stateToSet)
        return { [paramName]: stateToSet }
      }
    } else {
      const name = data.name
      const value = null
      const nextState = {}
      nextState[name] = value
      const stateToSet = Object.assign({}, this.state[paramName], {
        [name]: value
      })
      return { [paramName]: stateToSet }
    }
  }

  concentrationsCheckbox = name => event => {
    const paramName = this.props.match.params.modelName
    if (!this.state.concentrationsInitialCheck) {
      this.setState({ concentrationsInitialCheck: true })
      let state = this.state[paramName]
      const concentrationstoPlot = Object.assign({}, state.concentrationstoPlot, {
        [name]: event.target.checked
      })
      const stateToSet = Object.assign({}, this.state[paramName], {
        concentrationstoPlot: concentrationstoPlot
      })
      this.setState({ [paramName]: stateToSet })
    }
    let state = this.state[paramName]
    const selectedConcentrations = Object.assign({}, state.selectedConcentrations, {
      [name]: event.target.checked
    })
    const stateToSet = Object.assign({}, this.state[paramName], {
      selectedConcentrations: selectedConcentrations
    })
    this.setState({ [paramName]: stateToSet })
  }

  ratesCheckbox = name => event => {
    const paramName = this.props.match.params.modelName
    if (!this.state.ratesInitialCheck) {
      this.setState({ ratesInitialCheck: true })
      let state = this.state[paramName]
      const ratestoPlot = Object.assign({}, state.ratestoPlot, {
        [name]: event.target.checked
      })
      const stateToSet = Object.assign({}, this.state[paramName], {
        ratestoPlot: ratestoPlot
      })
      this.setState({ [paramName]: stateToSet })
    }
    let state = this.state[paramName]
    const ratesSelected = Object.assign({}, state.ratesSelected, {
      [name]: event.target.checked
    })
    const stateToSet = Object.assign({}, this.state[paramName], {
      ratesSelected: ratesSelected
    })
    this.setState({ [paramName]: stateToSet })
  }

  concentrationsCheckboxPlots = name => event => {
    const paramName = [this.props.match.params.modelName]
    let state = this.state[paramName].concentrationstoPlot
    const concentrationstoPlot = Object.assign({}, state, {
      [name]: event.target.checked
    })
    const stateToSet = Object.assign({}, this.state[paramName], {
      concentrationstoPlot: concentrationstoPlot
    })
    this.setState({ [paramName]: stateToSet })
  }

  ratesCheckboxPlots = name => event => {
    const paramName = [this.props.match.params.modelName]
    let state = this.state[this.props.match.params.modelName].ratestoPlot
    const ratestoPlot = Object.assign({}, state, {
      [name]: event.target.checked
    })
    const stateToSet = Object.assign({}, this.state[paramName], {
      ratestoPlot: ratestoPlot
    })
    this.setState({ [paramName]: stateToSet })
  }

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeTime = event => {
    const paramName = [this.props.match.params.modelName]
    const stateToSet = Object.assign({}, this.state[paramName], {
      [event.target.name]: event.target.value})
    this.setState({[paramName]: stateToSet })
  }

  handleChange = event => {
    const paramName = [this.props.match.params.modelName]
    let value
    if (event.target.value != '') {
      value = Number(event.target.value)
    } else {
    }
    const state = this.checkValue(event.target, value)
    const stateToSet = Object.assign({}, state[paramName], {
      [event.target.id]: value
    })
    console.log(stateToSet)
    this.setState({ [paramName]: stateToSet })
  }

  // handleChangeTime = name => event => {
  //   this.setState({
  //     [name]: event.target.value
  //   })
  // }

  // Test Buttons
  submit(evt) {
    evt.preventDefault()
    const { dispatchFetchSimData } = this.props
    const paramName = this.props.match.params.modelName
    const inputs = this.inputs(this.props.simDataRoot.metadata[paramName].inputs)
    console.log(inputs)
    const concentrations = this.concentrations(this.props.simDataRoot.metadata[paramName].outputs.concentrations)
    const flux = this.flux(this.props.simDataRoot.metadata[paramName].outputs.rates)
    console.log(this.props.simDataRoot.metadata[paramName].outputs)
    const data = {
      inputs: inputs,
      time: {
        t0: Number(this.state[paramName].t0),
        t_end: Number(this.state[paramName].t_end)
      },
      desired_outputs: {
        concentrations: concentrations,
        flux: flux,
        computed: []
      }
    }
    dispatchFetchSimData(data, paramName)
    this.setState({
      dataModel: null,
      hasUpdated: false
    })
  }

  inputs(data) {
    console.log(data)
    const paramName = this.props.match.params.modelName
    const state = this.state[paramName]
    console.log(state)
    let inputs = {}
    let count = Object.keys(data).length
    let keys = Object.keys(data)
    console.log(keys)
    for (let i = 0; i < count; i++) {
      if (state[data[keys[i]].name] === true) {
        console.log(keys[i])
        const value = state[keys[i]]
        inputs[keys[i]] = value
      }
    }
    return inputs
  }

  concentrations(data) {
    const paramName = this.props.match.params.modelName
    let concentrations = []
    let count = data.length
    let i
    for (i = 0; i < count; i++) {
      if (this.state[paramName].selectedConcentrations[data[i].variable] === true) {
        concentrations.push(data[i].variable)
      }
    }
    return concentrations
  }

  flux(data) {
    const paramName = this.props.match.params.modelName
    let flux = []
    let count = data.length
    let i
    for (i = 0; i < count; i++) {
      if (this.state[paramName].ratesSelected[data[i].variable] === true) {
        flux.push(data[i].variable)
      }
    }
    return flux
  }

  create(evt) {
    const time = this.props.simDataRoot.simData.time
    const HEX1 = this.props.simDataRoot.simData.fluxHEX1
    // const PYK = this.props.simDataRoot.simData.fluxPYK
    const GLC = this.props.simDataRoot.simData.GLC
    const data = []
    let i
    for (i = 0; i < time.length; i++) {
      data.push({ time: time[i], HEX1: HEX1[i], GLC: GLC[i] })
    }
    this.setState({
      dataModel: data
    })
  }

  // Needed For Prod
  dataFormaterTime = number => {
    const newNumber = number.toFixed(2)
    return newNumber
  }

  dataFormaterY = number => {
    const newNumber = number.toFixed(2)
    return newNumber
  }

  hidden(evt) {
    const name = evt.target.name
    const nameCheckBox = `${evt.target.name}`
    const value = evt.target.checked
    const nextState2 = {}
    nextState2[nameCheckBox] = value
    this.setState(nextState2)
  }

  phaseData(simData, y, x) {
    var size = Object.keys(simData)
    let stateData = []
    for (let i = 0; i < simData[size[0]].length; i++) {
      let prevObject = {}
      function buildObject(workingObject, name, data) {
        const newObject = Object.assign({}, workingObject, {
          [name]: data
        })
        prevObject = newObject
      }
      const dataSetx = simData[x]
      const datax = dataSetx[i]
      const workingObject = prevObject
      buildObject(workingObject, x, datax)
      const dataSety = simData[y]
      const datay = dataSety[i]
      const workingObjecty = prevObject
      buildObject(workingObjecty, y, datay)
      const superObjecty = prevObject
      stateData.push(superObjecty)
    }
    this.setState({
      phasePlotData: stateData
    })
  }

  initalData(simData) {
    var size = Object.keys(simData)
    let stateData = []
    for (let i = 0; i < simData[size[0]].length; i++) {
      let prevObject = {}
      function buildObject(workingObject, name, data) {
        const newObject = Object.assign({}, workingObject, {
          [name]: data
        })
        prevObject = newObject
      }

      Object.entries(simData).map(input => {
        const name = input[0]
        const dataSet = input[1]
        const data = dataSet[i]
        const workingObject = prevObject
        buildObject(workingObject, name, data)
      })
      const superObject = prevObject
      stateData.push(superObject)
    }
    this.setState({
      dataModel: stateData,
      hasUpdated: true,
      phasesToPlotXAxis: size[0],
      phasesToPlotYAxis: size[1]
    })
  }

  handleOptionChange(evt) {
    const name = evt.target.name
    const value = evt.target.value
    const nextState = {}
    nextState[name] = value
    this.setState(nextState)
  }
}

// REDUX Container Component
const mapStateToProps = state => {
  return {
    simDataRoot: state.simDataRoot
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchFetchSimData: (data, modelName) => dispatch(fetchSimData(data, modelName)),
    dispatchFetchMetadata: modelName => dispatch(fetchMetadata(modelName)),
    dispatchClearMetadata: () => dispatch(clearMetadata())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Simulator)

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue)
  }

  handleListItemClick = value => {
    this.props.onClose(value)
  }

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <div className="progress">
          <DialogTitle id="simple-dialog-title">Running Simulation</DialogTitle>
          <h6 />
          <CircularDeterminate />
          <h6 />
          <Timer>
            <Timer.Minutes /> Minutes <Timer.Seconds /> Seconds
          </Timer>
          <h1 />
        </div>
      </Dialog>
    )
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string
}

class CircularDeterminate extends React.Component {
  state = {
    completed: 0
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 20)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 })
  }

  render() {
    return (
      <div className="circle">
        <CircularProgress variant="determinate" value={this.state.completed} />
      </div>
    )
  }
}

CircularDeterminate.propTypes = {
  classes: PropTypes.object.isRequired
}
