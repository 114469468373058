const config = {
  gateway: {
    host: process.env.REACT_APP_GATEWAY_URL || 'http://localhost',
    port: process.env.REACT_APP_GATEWAY_PORT || ':9000',
    path: process.env.REACT_APP_GATEWAY_PATH || ''
  }
}

config.gateway.baseUrl = `${config.gateway.host}${config.gateway.port}${config.gateway.path}`

export default config
